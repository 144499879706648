import _shams from "./shams";
var exports = {};
var origSymbol = typeof Symbol !== "undefined" && Symbol;
var hasSymbolSham = _shams;

/** @type {import('.')} */
exports = function hasNativeSymbols() {
  if (typeof origSymbol !== "function") {
    return false;
  }
  if (typeof Symbol !== "function") {
    return false;
  }
  if (typeof origSymbol("foo") !== "symbol") {
    return false;
  }
  if (typeof Symbol("bar") !== "symbol") {
    return false;
  }
  return hasSymbolSham();
};
export default exports;